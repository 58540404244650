import {
  Box, Typography, Grid, Container,
  TextField, Card, Button, Breadcrumbs, Link, CircularProgress
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import ChevronRightIcon from 'src/icons/ChevronRight';
// import useSettings from '../../hooks/useSettings';
import { deviceApi } from 'src/api/deviceApi';
import useAuth from 'src/hooks/useAuth';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  fieldWrapper: {
    display: 'flex',
    marginBottom: '2rem',
  },
  mbusButtonsWrapper: {
    display: 'flex',
    marginBottom: '2rem',
    [theme.breakpoints.down('md')]: {
      marginBottom: '0',
    },
  },
  fieldButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
    },
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
    },
  },
  container: {
    marginBottom: '26px',
    width: '100%'
  },
  button: {
    borderRadius: '4px !important',
    height: '56px',
    width: '250px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: '2rem',
    },
  },
  scanMbusButton: {
    marginLeft: '1rem',
    [theme.breakpoints.down('md')]: {
      marginLeft: '0',
    },
  },
  loadingCircle: {
    color: '#006699',
    width: '20px !important',
    height: '20px !important',
    marginLeft: 5,
    marginRight: 5
  },
  link: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
  textField: {
    borderRadius: '4px !important',
  },
  text: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 500,
    fontStyle: 'normal',
    color: 'white'
  }
}));
const DeviceTesting: FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const modbus = useRef(null);
  const mbus = useRef(null);
  const classes = useStyles();
  // const { settings } = useSettings();
  const locale = localStorage.getItem('locale');
  const { t } = useTranslation(locale);

  const [serialNumber, setSerialNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState<any>(null);
  function resetStates() {
    setLoading(true);
    setSuccess(false);
    setError(false);
  }
  function setSuccessMessage(response) {
    setLoading(false);
    setError(false);
    setSuccess(true);
    setMessage(response);
  }
  function setErrorMessage(response) {
    setLoading(false);
    setError(true);
    setSuccess(false);
    setMessage(response);
  }
  useEffect(() => {
    if (!user?.permissions?.some((permission) => permission.includes('admin'))) { navigate('/admin/device'); }
  }, []);

  return (
    <>
      <Helmet>
        <title>{`${t('devices_form_header')} ${t('page_title_appending_title')}`}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          pt: 4,
          pb: 8
        }}
      >
        <Container maxWidth="xl">
          <Grid>
            <Grid
              item
              md={12}
            >
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/admin/device"
                  variant="subtitle2"
                >
                  {t('devices_form_header')}
                </Link>
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                >
                  {t('devices_testing_form_header')}
                </Typography>
              </Breadcrumbs>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                {t('devices_testing_form_header')}
              </Typography>
              <form>
                <Card sx={{ mt: 3 }}>
                  <Box sx={{ p: 3, pb: 0 }}>
                    <Grid
                      container
                      md={12}
                      className={classes.fieldWrapper}
                    >
                      <Grid
                        item
                        xs={12}
                      >
                        <TextField
                          InputProps={{
                            classes: {
                              root: classes.textField
                            },
                          }}
                          fullWidth
                          label={t('devices_testing_device_serial_field')}
                          name="serial"
                          // inputProps={{
                          //   classes: {
                          //     root: classes.textField
                          //   }
                          // }}
                          onChange={(e) => setSerialNumber(e.target.value)}
                          required
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      md={12}
                      className={classes.fieldWrapper}
                    >
                      <Grid
                        item
                        xs={12}
                        md={8}
                        lg={8}
                      >
                        <TextField
                          InputProps={{
                            classes: {
                              root: classes.textField
                            },
                          }}
                          inputRef={modbus}
                          fullWidth
                          label={t('devices_testing_modbus_registers_field')}
                          variant="outlined"
                          placeholder="reg_105, 106"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        lg={4}
                        className={classes.fieldButtonWrapper}
                      >
                        <Button
                          variant="contained"
                          disabled={serialNumber === ''}
                          className={classes.button}
                          onClick={() => {
                            resetStates();
                            deviceApi.sendModbusMessageToDeviceV2(serialNumber, modbus.current.value)
                              .then(setSuccessMessage)
                              .catch(setErrorMessage);
                          }}
                        >
                          {t('devices_testing_modbus_registers_button_field')}
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      md={12}
                      className={classes.fieldWrapper}
                    >
                      <Grid
                        item
                        xs={12}
                        md={8}
                        lg={8}
                        // sx={{ display: 'flex', alignItems: 'stretch' }}
                      >
                        <TextField
                          InputProps={{
                            classes: {
                              root: classes.textField
                            },
                          }}
                          inputRef={mbus}
                          fullWidth
                          label={t('devices_testing_single_mbus_register_field')}
                          variant="outlined"
                          placeholder="reg_0001"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        lg={4}
                        className={classes.fieldButtonWrapper}
                      >
                        <Button
                          variant="contained"
                          disabled={serialNumber === ''}
                          onClick={() => {
                            resetStates();
                            deviceApi.sendFetchSingleMbusMessageToDeviceV2(serialNumber, mbus.current.value)
                              .then(setSuccessMessage)
                              .catch(setErrorMessage);
                          }}
                          className={classes.button}
                        >
                          {t('devices_testing_single_mbus_register_field')}
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      md={12}
                      className={classes.mbusButtonsWrapper}
                    >
                      <Grid
                        item
                        xs={12}
                      >
                        <Button
                          variant="contained"
                          disabled={serialNumber === ''}
                          onClick={() => {
                            resetStates();
                            deviceApi.sendFetchAllMbusMessageToDeviceV2(serialNumber)
                              .then(setSuccessMessage)
                              .catch(setErrorMessage);
                          }}
                          className={classes.button}
                        >
                          {t('devices_testing_send_fetch_mbus_message')}
                        </Button>
                        <Button
                          variant="contained"
                          disabled={serialNumber === ''}
                          onClick={() => {
                            resetStates();
                            deviceApi.sendSearchAllMbusMessageToDeviceV2(serialNumber)
                              .then(setSuccessMessage)
                              .catch(setErrorMessage);
                          }}
                          className={clsx(classes.button, classes.scanMbusButton)}
                        >
                          {t('devices_testing_send_scan_mbus_message')}
                        </Button>
                      </Grid>
                    </Grid>
                    <br />
                    {loading && <CircularProgress className={classes.loadingCircle} />}
                    {success && (
                    <>
                      <p style={{ marginBottom: 0, color: 'green' }}>
                        {message?.message}
                      </p>
                      <small style={{ color: 'gray' }}>{message?.data}</small>
                    </>
                    )}
                    {error && (
                    <>
                      <p style={{ marginBottom: 0, color: 'red' }}>{message?.message}</p>
                      <small>{message?.response?.data?.message}</small>
                    </>
                    )}
                  </Box>
                </Card>
              </form>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
export default DeviceTesting;
