import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

const Commissioning = createSvgIcon(
  <svg
    version="1.0"
    width="24"
    height="24"
    viewBox="0 0 512.000000 512.000000"
    fill="#6b778c"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      fill="#6b778c"
      stroke="none"
    >
      <path
        d="M2303 5106 c-67 -16 -156 -70 -200 -121 -59 -67 -90 -146 -118 -305
-14 -80 -28 -154 -30 -165 -3 -14 -23 -27 -72 -44 -37 -13 -112 -43 -165 -67
l-98 -44 -129 90 c-70 49 -153 101 -184 114 -107 48 -231 44 -344 -11 -83 -40
-356 -313 -396 -396 -55 -113 -59 -237 -11 -344 13 -31 65 -114 114 -184 l90
-129 -44 -98 c-24 -53 -54 -128 -67 -165 -17 -49 -30 -69 -44 -72 -11 -2 -85
-16 -165 -30 -214 -38 -307 -89 -377 -206 -53 -89 -63 -148 -63 -369 0 -221
10 -280 63 -369 70 -117 163 -168 377 -206 80 -14 154 -28 165 -30 14 -3 27
-23 44 -72 13 -37 43 -112 67 -165 l44 -98 -90 -129 c-49 -70 -101 -153 -114
-184 -48 -107 -44 -231 11 -344 40 -83 313 -356 396 -396 113 -55 237 -59 344
-11 31 13 114 65 184 114 l129 90 98 -44 c53 -24 128 -54 165 -67 49 -17 69
-30 72 -44 2 -11 16 -85 30 -165 38 -214 89 -307 206 -377 89 -53 148 -63 369
-63 221 0 280 10 369 63 66 40 115 95 152 171 54 112 46 194 -23 236 -79 48
-158 12 -196 -88 -17 -44 -34 -70 -60 -90 l-35 -27 -206 0 c-200 0 -207 1
-239 23 -18 13 -39 36 -47 51 -7 15 -30 119 -50 232 -47 261 -40 252 -212 308
-70 23 -181 69 -246 102 -82 41 -130 59 -156 59 -42 0 -78 -21 -271 -159 -199
-141 -215 -139 -406 53 -192 191 -194 207 -53 406 138 193 159 229 159 271 0
25 -18 74 -59 156 -33 65 -79 176 -103 247 -57 171 -49 166 -300 210 -224 40
-256 51 -289 97 -23 33 -24 39 -24 239 0 199 1 206 23 238 13 18 36 39 51 47
16 8 120 30 232 50 259 46 249 39 307 211 24 71 70 182 103 247 41 82 59 131
59 156 0 42 -21 78 -159 271 -141 199 -139 215 53 406 191 192 207 194 406 53
193 -138 229 -159 271 -159 26 0 74 18 156 59 65 33 174 78 243 101 120 39
152 56 171 91 5 10 24 105 43 212 40 222 51 255 97 288 33 23 39 24 239 24
199 0 206 -1 238 -23 18 -13 39 -36 47 -51 7 -15 30 -119 50 -232 47 -261 40
-252 212 -308 70 -23 181 -69 246 -102 82 -41 130 -59 156 -59 42 0 78 21 271
159 199 141 215 139 406 -53 192 -191 194 -207 53 -406 -138 -193 -159 -229
-159 -271 0 -26 18 -74 59 -156 33 -65 78 -174 101 -243 39 -120 56 -152 91
-171 10 -5 105 -24 212 -43 222 -40 255 -51 288 -97 23 -33 24 -39 24 -240 l0
-206 -27 -35 c-20 -26 -46 -43 -90 -60 -100 -38 -136 -117 -88 -196 42 -69
124 -77 236 -23 77 37 131 86 173 155 51 85 61 144 61 366 0 221 -10 280 -63
369 -72 120 -161 167 -396 210 -90 17 -165 32 -166 33 -1 2 -12 32 -25 68 -12
36 -42 109 -67 162 l-43 98 90 129 c49 70 101 153 114 184 48 107 44 231 -11
344 -40 83 -313 356 -396 396 -113 55 -237 59 -344 11 -31 -13 -114 -65 -184
-114 l-129 -90 -98 44 c-53 24 -128 54 -165 67 -49 17 -69 30 -72 44 -2 11
-16 85 -30 165 -38 214 -89 307 -206 377 -90 53 -148 63 -374 62 -124 0 -220
-6 -252 -13z"
      />
      <path d="M2388 3819 c-110 -26 -188 -129 -188 -249 0 -85 32 -130 248 -351
120 -123 208 -222 217 -243 20 -47 19 -102 -4 -151 -19 -42 -305 -331 -351
-355 -42 -22 -87 -28 -137 -18 -45 9 -63 25 -268 229 -237 235 -270 258 -360
259 -61 0 -137 -34 -182 -81 -51 -54 -65 -100 -70 -234 -24 -589 389 -1118
976 -1252 39 -8 156 -18 281 -22 198 -8 220 -10 284 -35 37 -14 93 -43 123
-65 30 -21 248 -238 484 -482 237 -244 440 -449 452 -456 30 -17 92 -16 127 2
16 9 37 30 46 47 17 33 21 97 7 123 -5 8 -125 135 -267 282 -644 666 -704 721
-846 779 -117 47 -167 56 -390 64 -173 6 -231 12 -295 30 -339 93 -608 366
-695 706 -21 80 -39 314 -26 314 5 0 83 -77 174 -172 92 -94 193 -191 224
-214 136 -100 322 -114 477 -35 45 23 318 276 392 362 138 162 157 372 48 544
-17 28 -112 131 -210 231 -99 99 -179 184 -179 189 0 14 199 -3 290 -25 101
-25 249 -93 334 -154 78 -57 192 -174 245 -252 63 -94 100 -180 146 -344 4
-14 11 -133 15 -265 6 -198 11 -252 29 -309 25 -85 77 -189 129 -258 50 -66
896 -939 925 -954 103 -55 218 36 187 146 -8 27 -117 146 -452 490 -243 250
-456 478 -473 505 -70 109 -78 146 -86 405 -7 246 -16 303 -64 446 -124 365
-445 673 -813 780 -169 49 -398 68 -504 43z"
      />
    </g>
  </svg>,
  'Device'
);

export default Commissioning;
