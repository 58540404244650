import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

const Dashboard = createSvgIcon(
  <svg
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.81463 13.852C2.13264 13.1719 1.59179 12.3636 1.22317 11.4737C0.854542 10.5839 0.665407 9.62991 0.666632 8.66671C0.666632 4.61671 3.94997 1.33337 7.99997 1.33337C12.05 1.33337 15.3333 4.61671 15.3333 8.66671C15.3345 9.62991 15.1454 10.5839 14.7768 11.4737C14.4081 12.3636 13.8673 13.1719 13.1853 13.852"
      stroke="#546E7A"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.69992 11.9667C4.26594 11.5338 3.92179 11.0194 3.68725 10.4531C3.45272 9.88673 3.33241 9.27964 3.33326 8.66667C3.33326 6.08933 5.42259 4 7.99992 4"
      stroke="#546E7A"
      strokeWidth="1.33333"
      strokeLinecap="round"
    />
    <path
      d="M8 8.66667V6"
      stroke="#546E7A"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'Dashboard'
);

export default Dashboard;
