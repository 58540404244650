/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import styled from '@emotion/styled';
import type { AppBarProps } from '@material-ui/core';
import { AppBar, Box, FormControlLabel, IconButton, Switch, Toolbar, Tooltip, TooltipProps, Typography, tooltipClasses } from '@material-ui/core';
import { experimentalStyled, makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import clsx from 'clsx';
import { isUndefined } from 'lodash';
import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAuth from 'src/hooks/useAuth';
import ClipboardIcon from 'src/icons/Clipboard';
import MenuIcon from 'src/icons/Menu';
import wait from 'src/utils/wait';
import AccountPopover from './AccountPopover';
import ContentSearch from './ContentSearch';
import DashboardSidebar from './DashboardSidebar';
import LanguagePopover from './LanguagePopover';
import NotificationsPopover from './NotificationsPopover';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
    },
    position: 'relative'
  },
  supportIconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    zIndex: 9999,
    width: '50px',
    height: '50px',
    bottom: '50px',
    right: '50px',
    paddingTop: '2px',
    borderRadius: '50%',
    backgroundColor: '#008C3C',
    boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.65)',
    cursor: 'pointer',
  },
  menuIcon: {
    [theme.breakpoints.down('lg')]: {
      position: 'absolute',
      left: 10,
    },
    [theme.breakpoints.down('sm')]: {
      position: 'static',
      left: 0,
    },
  },
  monitoring: {
    position: 'absolute',
    left: 300,
    top: 20,
    [theme.breakpoints.down('lg')]: {
      left: 80,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
  avgProofSwitch: {
    position: 'absolute',
    left: 308,
    top: 14,
    [theme.breakpoints.down('lg')]: {
      left: 80,
    },
    [theme.breakpoints.down(700)]: {
      display: 'none'
    },
  },
  languageSelector: {
    marginLeft: theme.spacing(1),
    marginRight: '-20px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(0),
      marginRight: '0 !important',
      '& button': {
        paddingRight: 0
      },
    },
  },
  notificationsPopover: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(0),
      '& button': {
        paddingRight: 0
      },
    },
  },
  searchWrapper: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(0),
      display: 'none !important'
    },
  },
}));

interface DashboardNavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => boolean;
  isSidebarMobileOpen?: boolean;
}

const DashboardNavbarRoot = experimentalStyled(AppBar)(
  ({ theme }) => (
    {
      ...(
        theme.palette.mode === 'light' && {
          backgroundColor: theme.palette.primary.main,
          boxShadow: 'none',
          color: theme.palette.primary.contrastText
        }
      ),
      ...(
        theme.palette.mode === 'dark' && {
          backgroundColor: theme.palette.background.paper,
          borderBottom: `1px solid ${theme.palette.divider}`,
          boxShadow: 'none'
        }
      ),
      zIndex: theme.zIndex.drawer + 100
    }
  )
);

const handleChange = (value): void => {
  if (value) {
    localStorage.setItem('avg_proof_switch', 'true');
  } else {
    localStorage.removeItem('avg_proof_switch');
  }
  window.location.reload();
};

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
  />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'black !important',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '12px 4px 12px 8px',
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    width: '235px',
    fontSize: 12,
    border: '1px solid #dadde9',
  },
}));

const DashboardNavbar: FC<DashboardNavbarProps> = () => {
  const classes = useStyles();
  const { user } = useAuth();
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState<boolean>(false);
  const [isEmailCopied, setIsEmailCopied] = useState<boolean>(false);
  const [openSupportTooltip, setOpenSupportTooltip] = useState<boolean>(false);
  const locale = localStorage.getItem('locale');
  const { t } = useTranslation(locale);

  const handleTextCopied = async () => {
    setIsEmailCopied(true);
    navigator.clipboard.writeText('support@fortesdata.com');
    await wait(2000);
    setIsEmailCopied(false);
    setOpenSupportTooltip(false);
  };

  const handleOpenSupportTooltip = () => {
    setOpenSupportTooltip(!openSupportTooltip);
  };

  return (
    <DashboardNavbarRoot>
      <DashboardSidebar
        onMobileClose={(): void => setIsSidebarMobileOpen(false)}
        openMobile={isSidebarMobileOpen}
      />
      <Toolbar
        sx={{ minHeight: 64 }}
        className={classes.toolbar}
      >
        <IconButton
          className={classes.menuIcon}
          color="inherit"
          onClick={(): void => setIsSidebarMobileOpen(!isSidebarMobileOpen)}
          sx={{
            display: {
              lg: 'none'
            }
          }}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
        {/* {(user?.permissions?.includes('monitoring-read') || user?.permissions?.includes('navbar-avg-proof-switch-read')) && (
          <Box
            sx={{
              flexGrow: 1,
              ml: 2
            }}
          />
        )} */}
        {user?.permissions?.includes('navbar-avg-proof-switch-read') && (
          <Box
            sx={{ ml: 1 }}
            className={clsx({
              [classes.avgProofSwitch]: user?.permissions?.includes('navbar-avg-proof-switch-read') && user?.permissions?.includes('monitoring-read'),
              [classes.monitoring]: user?.permissions?.includes('navbar-avg-proof-switch-read') && !user?.permissions?.includes('monitoring-read'),
              [classes.monitoring]: !user?.permissions?.includes('navbar-avg-proof-switch-read') && user?.permissions?.includes('monitoring-read'),
            })}
          >
            <FormControlLabel
              control={(
                <Switch
                  checked={!isUndefined(localStorage.getItem('avg_proof_switch')?.length)}
                  color="secondary"
                  edge="start"
                  name="direction"
                  onChange={(event): void => handleChange(
                    event.target.checked
                  )}
                />
              )}
              label={(
                <Typography
                  component="p"
                  variant="subtitle2"
                >
                  {t('navbar_avg_proof_switch')}
                </Typography>
              )}
            />
          </Box>
        )}
        <Box className={classes.searchWrapper}>
          <ContentSearch />
        </Box>
        <Box className={classes.languageSelector}>
          <LanguagePopover />
        </Box>
        {user?.permissions?.includes('notification-read') && (
          <Box className={classes.notificationsPopover}>
            <NotificationsPopover />
          </Box>
        )}
        <Box sx={{ ml: 2 }}>
          <AccountPopover />
        </Box>
      </Toolbar>
      <div
        className={classes.supportIconWrapper}
        onClick={handleOpenSupportTooltip}
      >
        <HtmlTooltip
          open={openSupportTooltip}
          title={(
            <Typography
              variant="subtitle2"
            >
              {t('sidebar_support_text')}
&nbsp;support@fortesdata.com
              <ClipboardIcon
                sx={{ width: '24px', height: '17px', cursor: 'pointer', position: 'absolute' }}
                onClick={handleTextCopied}
              />
              {isEmailCopied && (
                <Alert
                  severity="success"
                  sx={{ paddingTop: '5px', paddingBottom: '5px' }}
                >
                  <div>
                    <strong>
                      {t('sidebar_support_text_copied')}
                    </strong>
                  </div>
                </Alert>
              )}
            </Typography>
          )}
        >
          {!openSupportTooltip ? (
            <svg
              width="21"
              height="24"
              viewBox="0 0 21 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.1176 24L10.8088 20.4H10.5C7.57647 20.4 5.09559 19.41 3.05735 17.43C1.01912 15.45 0 13.04 0 10.2C0 7.36 1.01912 4.95 3.05735 2.97C5.09559 0.99 7.57647 0 10.5 0C11.9618 0 13.3259 0.2648 14.5925 0.7944C15.8591 1.324 16.9709 2.054 17.9278 2.9844C18.8848 3.9148 19.6358 4.9948 20.181 6.2244C20.7262 7.454 20.9992 8.7792 21 10.2C21 11.7 20.748 13.14 20.244 14.52C19.74 15.9 19.0499 17.18 18.1736 18.36C17.2974 19.54 16.2577 20.61 15.0545 21.57C13.8514 22.53 12.5391 23.34 11.1176 24ZM10.4691 16.77C10.8191 16.77 11.1176 16.65 11.3647 16.41C11.6118 16.17 11.7353 15.88 11.7353 15.54C11.7353 15.2 11.6118 14.91 11.3647 14.67C11.1176 14.43 10.8191 14.31 10.4691 14.31C10.1191 14.31 9.82059 14.43 9.57353 14.67C9.32647 14.91 9.20294 15.2 9.20294 15.54C9.20294 15.88 9.32647 16.17 9.57353 16.41C9.82059 16.65 10.1191 16.77 10.4691 16.77ZM9.57353 12.96H11.4265C11.4265 12.36 11.4882 11.94 11.6118 11.7C11.7353 11.46 12.1265 11.02 12.7853 10.38C13.1559 10.02 13.4647 9.63 13.7118 9.21C13.9588 8.79 14.0824 8.34 14.0824 7.86C14.0824 6.84 13.7274 6.0748 13.0175 5.5644C12.3076 5.054 11.4685 4.7992 10.5 4.8C9.59412 4.8 8.83235 5.0452 8.21471 5.5356C7.59706 6.026 7.16471 6.6208 6.91765 7.32L8.64706 7.98C8.75 7.64 8.94559 7.3052 9.23382 6.9756C9.52206 6.646 9.94412 6.4808 10.5 6.48C11.0559 6.4792 11.473 6.6292 11.7514 6.93C12.0297 7.2308 12.1685 7.5608 12.1676 7.92C12.1676 8.26 12.0647 8.5652 11.8588 8.8356C11.6529 9.106 11.4059 9.3808 11.1176 9.66C10.3971 10.26 9.95976 10.7352 9.80576 11.0856C9.65176 11.436 9.57435 12.0608 9.57353 12.96Z"
                fill="#F6F6F6"
              />
            </svg>
          ) : (
            <svg
              width="21"
              height="24"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          )}

        </HtmlTooltip>
      </div>
    </DashboardNavbarRoot>
  );
};

export default DashboardNavbar;
