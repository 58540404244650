import { useRef, useState } from 'react';
import type { FC } from 'react';
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import GlobeIcon from 'src/icons/Globe';
import ChevronDownIcon from 'src/icons/ChevronDown';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  text: {
    color: 'white',
    fontSize: 14,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: 70,
  },
  popOver: {
    borderRadius: 4,
  },
  activeLanguage: {
    background: '#b3dcc4'
  },
}));

const LanguagePopover: FC = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const classes = useStyles();
  const locale = localStorage.getItem('locale');
  const { t } = useTranslation(locale);

  const languageOptions = {
    nl: {
      icon: 'https://assets.it22.nl/fortes-energy/admin/nl_flag.png',
      label: t('navbar_languages_dutch')
    },
    en: {
      icon: 'https://assets.it22.nl/fortes-energy/admin/uk_flag.svg',
      label: t('navbar_languages_english')
    },
    dk: {
      icon: 'https://assets.it22.nl/fortes-energy/admin/dk_flag.jpg',
      label: t('navbar_languages_danish')
    },
    de: {
      icon: 'https://assets.it22.nl/fortes-energy/admin/de_flag.png',
      label: t('navbar_languages_german')
    },
    fr: {
      icon: 'https://assets.it22.nl/fortes-energy/admin/fr_flag.png',
      label: t('navbar_languages_french')
    },
  };

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleChangeLanguage = (language: string): void => {
    localStorage.setItem('locale', language);
    window.location.reload();
  };

  // const selectedOption = languageOptions[i18n.language];

  return (
    <>
      <IconButton
        onClick={handleOpen}
        ref={anchorRef}
      >
        <Box
          className={classes.text}
        >
          <GlobeIcon />
          {/* {selectedOption?.label} */}
          <ChevronDownIcon />
        </Box>
      </IconButton>
      <Popover
        className={classes.popOver}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240, borderRadius: '4px !important' }
        }}
      >
        {Object.keys(languageOptions)?.map((language) => (
          <MenuItem
            className={clsx({
              [classes.activeLanguage]: locale === language
            })}
            onClick={() => handleChangeLanguage(language)}
            key={language}
          >
            <ListItemIcon>
              <Box
                sx={{
                  display: 'flex',
                  '& img': {
                    width: '100%'
                  }
                }}
              >
                <img
                  alt={languageOptions[language].label}
                  src={languageOptions[language].icon}
                />
              </Box>
            </ListItemIcon>
            <ListItemText
              primary={(
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                >
                  {languageOptions[language].label}
                </Typography>
              )}
            />
          </MenuItem>
        ))}
      </Popover>
    </>
  );
};

export default LanguagePopover;
