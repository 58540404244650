import { CssBaseline, ThemeProvider } from '@material-ui/core';
import type { FC } from 'react';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Toaster } from 'react-hot-toast';
import { LightgalleryProvider } from 'react-lightgallery';
import { useRoutes } from 'react-router-dom';
import RTL from './components/RTL';
import SplashScreen from './components/SplashScreen';
import useAuth from './hooks/useAuth';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
import './i18n';
import routes from './routes';
import { createCustomTheme } from './theme';

const App: FC = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const auth = useAuth();
  const tagManagerArgs = [{
    trackingId: process.env.REACT_APP_GA_TRACKING_ID
  }];

  useScrollReset();

  useEffect(() => {
    // gtm.initialize(gtmConfig);
    // TagManager.initialize(tagManagerArgs);
    ReactGA.initialize(tagManagerArgs);
  }, []);

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme
  });

  return (
    <ThemeProvider theme={theme}>
      <LightgalleryProvider
        lightgallerySettings={
                    {
                    }
                }
        galleryClassName="docs_gallery"
      >
        <RTL direction={settings.direction}>
          <CssBaseline />
          <Toaster position="top-center" />
          {auth.isInitialized ? content : <SplashScreen />}
        </RTL>
      </LightgalleryProvider>
    </ThemeProvider>
  );
};

export default App;
