import type { User, UserOverviewProps } from 'src/types/user';
import axios from 'src/utils/axios';
import pureAxios from 'axios';
import parse from 'src/utils/parse';
import { isUndefined, omit } from 'lodash';
import logger from 'src/utils/logger';
import i18next from 'i18next';

class UserApi {
  async create(user: User): Promise<User> {
    return new Promise((resolve, reject) => {
      // Create User
      const prop = 'submit';
      delete user[prop];
      axios.post(`${process.env.REACT_APP_USERS_URL}`, user).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        }
      }).catch((err) => {
        logger(err, 'error');
        reject(err);
      });
    });
  }

  async edit(user: User): Promise<User> {
    return new Promise((resolve, reject) => {
      // Update User
      const props = ['submit'];
      if (user?.password?.trim()?.length === 0) {
        props.push('password');
      }
      const payload = omit(user, props);

      axios.put(`${process.env.REACT_APP_USERS_URL}/${user.user_id}`, payload).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        }
      }).catch((err) => {
        logger(err, 'error');
        reject(err);
      });
    });
  }

  async updateProfile(user: User): Promise<User> {
    return new Promise((resolve, reject) => {
      // Update User
      const props = ['submit'];
      if (user?.password?.trim()?.length === 0) {
        props.push('password');
      }
      const payload = omit(user, props);

      axios.put(`${process.env.REACT_APP_USERS_URL}/updateUser/${user.user_id}`, payload).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        }
      }).catch((err) => {
        logger(err, 'error');
        reject(err);
      });
    });
  }

  async updateSetting(data: any): Promise<User> {
    return new Promise((resolve, reject) => {
      // Update Alarm Settings
      axios.patch(`${process.env.REACT_APP_USERS_URL}/alarmSetting`, data).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        }
      }).catch((err) => {
        logger(err, 'error');
        reject(err);
      });
    });
  }

  async globalSearch(): Promise<any> {
    return new Promise((resolve, reject) => {
      // Update Global Search
      const search = localStorage.getItem('global_search');
      const data: any = {
        Filter: {
          userSortList: {
            created_at: 'DESC'
          },
          clientSortList: {
            'client.created_at': 'DESC'
          },
          deviceSortList: {
            'device.updated_at': 'DESC'
          },
          serviceClientSortList: {
            'client.created_at': 'DESC'
          },
          projectSortList: {
            'project.created_at': 'ASC'
          },
          page: 0,
          limit: 20
        },
        search
      };

      axios.post(`${process.env.REACT_APP_USERS_URL}/global_search`, data).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        }
      }).catch((err) => {
        logger(err, 'error');
        reject(err);
      });
    });
  }

  async getAll(page: number, limit: number, currentTab: string, search: string, roleIdList: any, sortColumn: any, sortDirection: boolean, clientId: number): Promise<UserOverviewProps> {
    return new Promise((resolve, reject) => {
      // Get All
      let sortList = {};
      if (sortColumn.name) {
        sortList = {
          first_name: sortDirection ? 'ASC' : 'DESC',
          last_name: sortDirection ? 'ASC' : 'DESC',
          email: sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.address) {
        sortList = {
          city: sortDirection ? 'ASC' : 'DESC',
          country: sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.role_id) {
        sortList = {
          role_id: sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.is_activated) {
        sortList = {
          is_activated: sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.created_at) {
        sortList = {
          created_at: sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.last_seen) {
        sortList = {
          last_seen: sortDirection ? 'ASC' : 'DESC',
        };
      }

      const filter: any = {
        page,
        limit,
        sortList
      };

      if (Object.keys(sortList)?.length === 0) {
        const sortListProp = 'sortList';
        delete filter[sortListProp];
      }

      let data: any;

      const formattedRoleIdList = roleIdList?.filter((role) => role !== -1);

      if (search?.length > 0) {
        data = {
          Filter: filter,
          search,
          client_id: clientId,
          roleIdList: formattedRoleIdList,
        };
      } else if (formattedRoleIdList?.length > 0) {
        data = {
          Filter: filter,
          client_id: clientId,
          roleIdList: formattedRoleIdList,
        };
      } else {
        data = {
          Filter: filter,
          client_id: clientId
        };
      }

      if (!isUndefined(currentTab)) {
        if (+currentTab !== -1) {
          filter.active = !!currentTab;
        }
      }

      axios.post(`${process.env.REACT_APP_USERS_URL}/getAllUser`, data).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getAllAdmin(page: number, limit: number, currentTab: number, search: string, roleIdList: any, sortColumn: any, sortDirection: boolean): Promise<UserOverviewProps> {
    return new Promise((resolve, reject) => {
      // Get All Admin
      let sortList = {};
      if (sortColumn.name) {
        sortList = {
          first_name: sortDirection ? 'ASC' : 'DESC',
          last_name: sortDirection ? 'ASC' : 'DESC',
          email: sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.address) {
        sortList = {
          city: sortDirection ? 'ASC' : 'DESC',
          country: sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.role_id) {
        sortList = {
          role_id: sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.is_activated) {
        sortList = {
          is_activated: sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.created_at) {
        sortList = {
          created_at: sortDirection ? 'ASC' : 'DESC',
        };
      }

      const filter: any = {
        page,
        limit,
        sortList
      };

      if (Object.keys(sortList)?.length === 0) {
        const sortListProp = 'sortList';
        delete filter[sortListProp];
      }

      let data: any;

      if (search?.length > 0) {
        data = {
          Filter: filter,
          search,
          is_admin_module: true,
          roleIdList,
        };
      } else if (roleIdList) {
        data = {
          Filter: filter,
          roleIdList,
          is_admin_module: true
        };
      } else {
        data = {
          Filter: filter,
          is_admin_module: true
        };
      }

      if (!isUndefined(currentTab)) {
        if (currentTab !== -1) {
          filter.active = !!currentTab;
        }
      }

      axios.post(`${process.env.REACT_APP_USERS_URL}/getAllUser`, data).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getById(userId: number): Promise<User> {
    return new Promise((resolve, reject) => {
      // Get By Id User
      axios.get(`${process.env.REACT_APP_USERS_URL}/${userId}`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async enable2FAQRCode(keyCloakId: string): Promise<User> {
    return new Promise((resolve, reject) => {
      // Enable 2FA QR Code
      axios.post(`${process.env.REACT_APP_USERS_URL}/updateUserQrCodeByKc?id=${keyCloakId}`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data:any = response?.data?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getByKeycloakId(keyCloakId: number): Promise<User> {
    return new Promise((resolve, reject) => {
      // Get By Id User
      axios.get(`${process.env.REACT_APP_USERS_URL}/getUserByKeycloakID/${keyCloakId}`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data:any = response?.data?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getUserPermissions(token: string = ''): Promise<any> {
    return new Promise((resolve, reject) => {
      // Get User Permissions
      if (token?.length > 0) {
        pureAxios.post(`${process.env.REACT_APP_USERS_URL}/getPermissions`, {}, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }).then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            const data:any = response?.data;
            resolve(data);
          } else {
            reject(new Error(i18next.t('invalid_server_response')));
          }
        }).catch((error) => {
          logger(error, 'error');
          reject(new Error(i18next.t('internal_server_error')));
        });
      } else {
        axios.post(`${process.env.REACT_APP_USERS_URL}/getPermissions`).then((response) => {
          response = parse(response);
          if (!isUndefined(response?.data)) {
            const data:any = response?.data;
            resolve(data);
          } else {
            reject(new Error(i18next.t('invalid_server_response')));
          }
        }).catch((error) => {
          logger(error, 'error');
          reject(new Error(i18next.t('internal_server_error')));
        });
      }
    });
  }

  async verifyUserByKeycloakId(keyCloakId: string): Promise<User> {
    return new Promise((resolve, reject) => {
      // Verify User By Keycloak Id
      pureAxios.post(`${process.env.REACT_APP_USERS_URL}/verifyUserByKeycloakID/${keyCloakId}`, {}, {
        headers: {
          Authorization: localStorage.getItem('tempAccessToken') ? `Bearer ${localStorage.getItem('tempAccessToken')}` : ''
        }
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getKeycloakIdByEmail(email: string): Promise<any> {
    return new Promise((resolve, reject) => {
      // Get Email By Keycloak Id
      pureAxios.get(`${process.env.REACT_APP_USERS_URL}/getKeycloakIdByEmail/${email}`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getServiceAccountToken(): Promise<any> {
    return new Promise((resolve, reject) => {
      // Get Service Account
      pureAxios.get(`${process.env.REACT_APP_USERS_URL}/getServiceAccountToken`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getAllUserAutoComplete(clientId: number, search: string, returnUserWithoutClient: boolean = false): Promise<User[]> {
    return new Promise((resolve, reject) => {
      // Get All Users by Autocomplete
      const data = {
        return_user_without_client: returnUserWithoutClient,
        client_id: clientId,
        search
      };

      let prop = '';

      if (returnUserWithoutClient) {
        prop = 'client_id';
        delete data[prop];
      }

      if (!returnUserWithoutClient) {
        prop = 'return_user_without_client';
        delete data[prop];
      }

      if (search.trim()?.length === 0) {
        prop = 'search';
        delete data[prop];
      }

      axios.post(`${process.env.REACT_APP_USERS_URL}/getAllUserAutoComplete`, data).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res:any = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async deActivate(userId: string, isActive: number): Promise<User> {
    return new Promise((resolve, reject) => {
      // De-active User
      axios.patch(`${process.env.REACT_APP_USERS_URL}/delete/${userId}`, {
        is_activated: !isActive
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async bulkDeActivate(userIds: string[]): Promise<User> {
    return new Promise((resolve, reject) => {
      // Bulk De-active Users
      axios.post(`${process.env.REACT_APP_USERS_URL}/bulk_deactive`, {
        list: userIds
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async bulkDelete(userIds: string[]): Promise<User> {
    return new Promise((resolve, reject) => {
      // Bulk De-active Users
      axios.post(`${process.env.REACT_APP_USERS_URL}/bulkUserDeactivation`, {
        list: userIds
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async bulkDeleteThreeScaleUsers(userIds: string[]): Promise<User> {
    return new Promise((resolve, reject) => {
      // Bulk De-active Users
      axios.post(`${process.env.REACT_APP_CLIENTS_3SCALE_URL}/accountDelete`, {
        list: userIds
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async sendEmail(emailProperties: any): Promise<User[]> {
    // Send Email
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.REACT_APP_EMAILS_URL}`, emailProperties).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res:any = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async checkEmailLimitExceeded(): Promise<User[]> {
    // Check Email Limit Exceeded
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.REACT_APP_EMAILS_URL}/checkEmailLimitExceeded`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res:any = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async getAllMessages(page: number, limit: number, currentTab: string, search: string, sortColumn: any, sortDirection: boolean): Promise<User[]> {
    // Get All Messages

    let sortList = {};

    if (sortColumn.to) {
      sortList = {
        to: sortDirection ? 1 : -1,
      };
    }

    if (sortColumn.recipient_name) {
      sortList = {
        recipient_name: sortDirection ? 1 : -1,
      };
    }

    if (sortColumn.subject) {
      sortList = {
        subject: sortDirection ? 1 : -1,
      };
    }

    if (sortColumn.status) {
      sortList = {
        status: sortDirection ? 1 : -1,
      };
    }

    if (sortColumn.updatedAt) {
      sortList = {
        updatedAt: sortDirection ? 1 : -1,
      };
    }

    const filter: any = {
      page,
      limit,
      sortList
    };

    if (Object.keys(sortList)?.length === 0) {
      const sortListProp = 'sortList';
      delete filter[sortListProp];
    }

    let data: any;

    if (search?.length > 0) {
      data = {
        Filter: filter,
        search,
      };
    } else {
      data = {
        Filter: filter,
      };
    }

    if (!isUndefined(currentTab)) {
      if (currentTab !== '-1') {
        data.status = currentTab === 'passed';
      }
    }
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.REACT_APP_EMAILS_URL}/getAllMessages`, data).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res:any = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async resendFailedMessages(idList: any): Promise<any> {
    // Resend Failed Messages
    const data = {
      ids: idList
    };

    return new Promise((resolve, reject) => {
      axios.patch(`${process.env.REACT_APP_EMAILS_URL}/resendFailedMessages`, data).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res:any = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async getEmailById(emailId: any): Promise<User> {
    return new Promise((resolve, reject) => {
      // Get By Id User
      axios.get(`${process.env.REACT_APP_EMAILS_URL}/getEmailById/${emailId}`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async resetPassword(keycloakUserId: string, newPassword: string): Promise<any[]> {
    // Reset Password
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.REACT_APP_USERS_URL}/reset_password`, {
        keycloak_user_id: keycloakUserId,
        new_password: newPassword,
        resetPassword: newPassword,
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async generateQRCode(): Promise<any[]> {
    // generateQRCode
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.REACT_APP_USERS_URL?.replace('v1/user', 'v1')}/auth/qr-code`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async verifyOTP(otp: any): Promise<any[]> {
    // verifyOTP
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.REACT_APP_USERS_URL?.replace('v1/user', 'v1')}/auth/verify?otp=${otp}`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async uploadFile(event: string, userType: string, previousImage: string = ''): Promise<any> {
    // Reset Password
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', event);
      formData.append('type', userType);
      // if (previousImage?.length > 0 && previousImage !== 'dummy_image.png') {
      // formData.append('previous_image', previousImage);
      // }
      logger(previousImage);
      axios.post(`${process.env.REACT_APP_IMAGE_UPLOAD_URL}/upload`, formData).then((response) => {
        response = parse(response);
        if (!isUndefined(response)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async updateProfileImageByKeyCloakId(keyCloakId: string, profileImage: string): Promise < any > {
    // Update Profile Image By Keycloak Id
    return new Promise((resolve, reject) => {
      axios.patch(`${process.env.REACT_APP_USERS_URL}/updateProfileImageByKeyCloakId/${keyCloakId}`, {
        profile_image: profileImage
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async updateUserLastSeen(): Promise<any> {
    // Update User Last Seen
    return new Promise((resolve, reject) => {
      axios.patch(`${process.env.REACT_APP_USERS_URL}/updateUserLastSeen`).then((response) => {
        response = parse(response);
        if (!isUndefined(response)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }
}

export const userApi = new UserApi();
