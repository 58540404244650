import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { FC } from 'react';
import { notificationApi } from 'src/api/notificationApi';
import { ViewNotificationProps } from 'src/types/notification';
import logger from 'src/utils/logger';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  deleteBtn: {
    color: '#AB3535',
    '&:hover': {
      background: 'transparent'
    }
  },
  cancelBtn: {
    color: '#6b778c',
    '&:hover': {
      background: 'transparent'
    }
  },
  loadingCircle: {
    color: '#006699',
    width: '20px !important',
    height: '20px !important',
    marginLeft: 5,
    marginRight: 5
  },
}));
const ViewNotificationModal: FC<ViewNotificationProps> = (props) => {
  const classes = useStyles();
  const { notification, setSelectedNotification, openViewNotificationModal, setIsSubmitting, setOpenViewNotificationModal } = props;
  const handleModalClose = () => {
    setIsSubmitting(false);
    setOpenViewNotificationModal(false);
    setSelectedNotification({});
    setTimeout(() => {
      setIsSubmitting(true);
    }, 500);
  };

  const locale = localStorage.getItem('locale');
  const { t } = useTranslation(locale);

  const handleSingleRead = async () => {
    try {
      const res: any = await notificationApi.singleRead(notification?.notification_id);
      logger(res);
    } catch (err) {
      logger(err, 'error');
    }
  };

  if (notification?.notification_id) {
    handleSingleRead();
  }

  return (
    <Dialog
      open={openViewNotificationModal}
      onClose={handleModalClose}
    >
      <DialogTitle>
        {notification?.notification && (
          <strong>{notification?.notification[`title_${locale}`]}</strong>
        )}
      </DialogTitle>
      <DialogContent>
        {notification?.notification && (
          <DialogContentText
            dangerouslySetInnerHTML={{ __html: notification?.notification[`description_${locale}`] }}
          />
        )}
      </DialogContent>
      <DialogActions>
        {notification?.notification && (
          <Button
            autoFocus
            onClick={handleModalClose}
            className={classes.cancelBtn}
          >
            {t('pop_over_close_button')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

ViewNotificationModal.propTypes = {
  notification: PropTypes.object,
  openViewNotificationModal: PropTypes.bool,
  setOpenViewNotificationModal: PropTypes.func,
};

ViewNotificationModal.defaultProps = {
  openViewNotificationModal: false
};

export default ViewNotificationModal;
