import { FC, useState } from 'react';
import {
  InputAdornment,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import SearchIcon from '../../icons/Search';

import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import useAuth from 'src/hooks/useAuth';

const useStyles = makeStyles((theme) => ({
  queryField: {},
  textField: {
    height: 40,
    borderRadius: 4,
    background: 'white',
    [theme.breakpoints.down('xs')]: {
      width: 150,
    }
  },
  searchIcon: {
    color: '#263238'
  },
  searchBox: {
    padding: 10,
    borderRadius: 4,
    height: 42,
    width: 250,
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
  },
  loadingCircle: {
    color: '#006699',
    width: '20px !important',
    height: '20px !important',
    marginLeft: 5,
    marginRight: 5
  },
}));

const ContentSearch: FC = () => {
  const classes = useStyles();
  const locale = localStorage.getItem('locale');
  const { t } = useTranslation(locale);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const { user } = useAuth();

  const handleChange = (event) => {
    const searchTerm = event?.target?.value;
    setSearch(searchTerm);
    if (searchTerm?.length === 0 && event.key !== 'Enter') {
      setIsSubmitting(true);
    }
  };

  const handleSearch = (event) => {
    event.persist();
    if (event.key === 'Enter') {
      localStorage.setItem('global_search', search);
      window.location.href = '/admin/search';
    }
  };
  return (
    user?.permissions?.includes('global-search-read') && (
      <TextField
        size="small"
        InputProps={{
          classes: {
            root: classes.textField
          },
          startAdornment: (
            <InputAdornment
              position="start"
            >
              { isSubmitting ? (<CircularProgress className={classes.loadingCircle} />) : <SearchIcon className={classes.searchIcon} />}
            </InputAdornment>
          )
        }}
        onKeyPress={handleSearch}
        onChange={handleChange}
        placeholder={t('navbar_search')}
        value={search}
        variant="outlined"
      />
    )
  );
};

export default ContentSearch;
