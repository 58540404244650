import {
  Avatar,
  Badge,
  Box,
  Button,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Tooltip,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import type { ElementType, FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { notificationApi } from 'src/api/notificationApi';
import BellIcon from 'src/icons/Bell';
import ExclamationCircleIcon from 'src/icons/ExclamationCircle';
import InformationCircleIcon from 'src/icons/InformationCircle';
import { NotificationList } from 'src/types/notification';
import logger from 'src/utils/logger';
import wait from 'src/utils/wait';
import ViewNotificationModal from './ViewNotificationModal';

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  viewAllButton: {
    background: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    height: 50,
    color: 'white',
    '&:hover': {
      background: 'white',
      height: 50,
      color: theme.palette.primary.main,
    },
  },
  markAllAsReadIcon: {
    width: '25px',
    height: '25px',
    cursor: 'pointer',
    '&:hover': {
      '& path': {
        fill: '#008C3C',
      }
    }
  },
  notification: {
    cursor: 'pointer',
  },
  unRead: {
    background: 'rgba(0, 140, 60, .2)'
  },
}));

const iconsMap: Record<string, ElementType> = {
  information: InformationCircleIcon,
  warning: ExclamationCircleIcon
};

const NotificationsPopover: FC = () => {
  const classes = useStyles();
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const page = 0;
  const limit = 5;
  const sortDirection = false;
  const sortColumn = {
    notification_id: true
  };

  const [openViewNotificationModal, setOpenViewNotificationModal] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<NotificationList[]>([]);
  const [selectedNotification, setSelectedNotification] = useState<any>({});
  const [totalCount, setTotalCount] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const locale = localStorage.getItem('locale');
  const { t } = useTranslation(locale);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(true);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const getAll = async () => {
    if (isSubmitting) {
      try {
        const res: any = await notificationApi.getAllUserNotification(page, limit, '', sortColumn, sortDirection);
        setNotifications(res?.data);
        setTotalCount(res?.totalReadCount);
        setIsSubmitting(false);
      } catch (err) {
        logger(err, 'error');
        setIsSubmitting(false);
        setNotifications([]);
        setTotalCount(0);
      }
    }
  };

  useEffect(() => {
    getAll();
  }, [isSubmitting]);

  useEffect(() => {
    const apiTimer = setInterval(() => {
      getAll();
    }, 60000);
    return () => clearInterval(apiTimer);
  }, []);

  const handleBulkRead = async () => {
    try {
      const res: any = await notificationApi.bulkRead();
      setIsSubmitting(false);
      await wait(500);
      setIsSubmitting(true);
      logger(res);
    } catch (err) {
      logger(err, 'error');
      setIsSubmitting(false);
      setNotifications([]);
      setTotalCount(0);
    }
  };

  const handleSingleRead = async (e, notification) => {
    try {
      setSelectedNotification(notification);
      setOpenViewNotificationModal(true);
    } catch (err) {
      logger(err, 'error');
      setIsSubmitting(false);
      setNotifications([]);
      setOpenViewNotificationModal(false);
      setTotalCount(0);
    }
  };

  return (
    <>
      <Tooltip title="Notifications">
        <IconButton
          color="inherit"
          ref={anchorRef}
          onClick={handleOpen}
        >
          <Badge
            color="error"
            badgeContent={totalCount}
          >
            <BellIcon fontSize="small" />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 320, borderRadius: '4px !important' }
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography
            className={classes.title}
            color="textPrimary"
            variant="h6"
          >
            {t('navbar_notification')}
            {notifications?.length > 0 && (
              <Tooltip title={t('navbar_notification_mark_all_as_read')}>
                <svg
                  onClick={handleBulkRead}
                  className={classes.markAllAsReadIcon}
                  version="1.0"
                  xmlns="http://www.w3.org/2000/svg"
                  width="512.000000pt"
                  height="512.000000pt"
                  viewBox="0 0 512.000000 512.000000"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <g
                    transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                    fill="#172b4d"
                    stroke="none"
                  >
                    <path d="M2469 5087 c-20 -12 -574 -435 -1232 -940 -1061 -815 -1198 -922
                  -1216 -961 l-21 -43 2 -1344 3 -1344 22 -66 c59 -174 202 -309 382 -363 50
                  -14 252 -16 2152 -16 l2097 0 68 21 c169 52 309 188 367 359 l22 65 3 1344 3
                  1344 -22 43 c-19 40 -149 143 -1221 965 -659 506 -1214 929 -1231 940 -46 27
                  -131 26 -178 -4z m1126 -1178 c567 -435 1031 -795 1033 -799 2 -5 -331 -269
                  -740 -587 l-744 -578 -219 183 c-280 233 -293 242 -365 242 -72 0 -87 -9 -364
                  -242 l-219 -184 -744 579 c-409 318 -743 582 -741 587 2 5 156 125 343 268
                  187 143 651 499 1030 791 380 291 692 530 695 530 3 0 469 -355 1035 -790z
                  m-2493 -1747 c317 -246 577 -451 577 -457 0 -5 -185 -163 -411 -350 -225 -187
                  -520 -431 -654 -543 l-244 -202 0 1060 0 1061 78 -60 c42 -34 337 -262 654
                  -509z m3646 -1020 l-3 -528 -652 541 c-358 297 -650 545 -649 550 1 6 295 237
                  652 515 l649 506 3 -528 c1 -290 1 -766 0 -1056z m-1231 18 l944 -785 -951 -3
                  c-522 -1 -1378 -1 -1901 0 l-951 3 948 788 c521 433 952 786 957 784 6 -2 435
                  -356 954 -787z"
                    />
                  </g>
                </svg>
              </Tooltip>
            )}
          </Typography>
        </Box>
        {
          notifications.length === 0
            ? (
              <Box sx={{ p: 2 }}>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                >
                  {t('navbar_no_notification')}
                </Typography>
              </Box>
            )
            : (
              <>
                <List disablePadding>
                  {notifications?.map((notification) => {
                    const Icon = iconsMap[notification?.notification?.notification_type];

                    return (
                      <ListItem
                        divider
                        key={notification?.notification_id}
                        className={clsx(classes.notification, {
                          [classes.unRead]: !notification?.read_at
                        })}
                        onClick={(e) => handleSingleRead(e, notification)}
                      >
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              backgroundColor: 'primary.main',
                              color: 'primary.contrastText'
                            }}
                          >
                            <Icon fontSize="small" />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={(
                            <Link
                              color="textPrimary"
                              sx={{ cursor: 'pointer' }}
                              underline="none"
                              variant="subtitle2"
                            >
                              {notification?.notification[`title_${locale}`]?.length > 35
                                ? `${notification?.notification[`title_${locale}`]?.subtring(0, 32)}...` : notification?.notification[`title_${locale}`]}
                              {}
                            </Link>
                          )}
                          secondary={notification?.notification[`description_${locale}`]?.length > 35
                            ? `${notification?.notification[`description_${locale}`]?.subtring(0, 32)}...` : notification?.notification[`description_${locale}`]}
                        />
                      </ListItem>
                    );
                  })}
                </List>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    p: 1
                  }}
                >
                  <Link
                    color="textPrimary"
                    sx={{ cursor: 'pointer', width: '100%', }}
                    underline="none"
                    variant="subtitle2"
                    href="/admin/user_notification"
                  >
                    <Button
                      className={classes.viewAllButton}
                      fullWidth
                      color="primary"
                      size="small"
                      variant="outlined"
                    >
                      {t('navbar_notification_view_all')}
                    </Button>
                  </Link>
                </Box>
              </>
            )
        }
      </Popover>
      <ViewNotificationModal
        setSelectedNotification={setSelectedNotification}
        notification={selectedNotification}
        openViewNotificationModal={openViewNotificationModal}
        setOpenViewNotificationModal={setOpenViewNotificationModal}
        setIsSubmitting={setIsSubmitting}
      />
    </>
  );
};

export default NotificationsPopover;
