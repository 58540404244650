import type { FC, ReactNode } from 'react';
// import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

interface GuestGuardProps {
  children: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const { isAuthenticated, is2FAEnabled } = useAuth();
  if (isAuthenticated && !is2FAEnabled) {
    window.location.href = '/dashboard';
    // return <Navigate to="/dashboard" />;
  }

  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
