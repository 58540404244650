import ReactPDF from '@intelllex/react-pdf';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import TimeIcon from 'src/icons/X';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiDialog-paper': {
      height: '100%',
      borderRadius: 4,
    }
  },
  deleteBtn: {
    color: '#AB3535',
    '&:hover': {
      background: 'transparent'
    }
  },
  cancelBtn: {
    color: '#6b778c',
    '&:hover': {
      background: 'transparent'
    }
  },
  loadingCircle: {
    color: '#006699',
    width: '20px !important',
    height: '20px !important',
    marginLeft: 5,
    marginRight: 5
  },
}));

interface TermsAndConditionalModalProps {
  openTermsAndConditionsModal: any;
  closeTermsAndConditions: any;
}

const TermsAndConditionalModal: FC<TermsAndConditionalModalProps> = (props) => {
  const classes = useStyles();
  const { openTermsAndConditionsModal, closeTermsAndConditions } = props;

  const locale = localStorage.getItem('locale');
  const { t } = useTranslation(locale);

  return (
    <Dialog
      open={openTermsAndConditionsModal}
      onClose={closeTermsAndConditions}
      fullScreen
      maxWidth="xl"
      sx={{
        height: '100%',
      }}
      fullWidth
      className={classes.root}
    >
      <DialogTitle>
        <strong>{t('sidebar_terms_and_conditions_title')}</strong>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeTermsAndConditions}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <TimeIcon />
      </IconButton>
      <DialogContent>
        <ReactPDF
          url="/terms-of-use-fortes-mercurius-application-2024.pdf"
          showProgressBar
          showToolbox
        />
      </DialogContent>
    </Dialog>
  );
};

TermsAndConditionalModal.propTypes = {
  openTermsAndConditionsModal: PropTypes.bool,
  closeTermsAndConditions: PropTypes.func,
};

export default TermsAndConditionalModal;
