const getMbusRegisterUnit = (key): string => {
  let regUnit;
  switch (key) {
    case 'MBUS_METER_FLOW':
      regUnit = ' L';
      break;
    case 'MBUS_METER_VOLUME':
      regUnit = ' m<sup>3<sup>';
      break;
    case 'MBUS_METER_POWER':
      regUnit = ' W';
      break;
    case 'MBUS_METER_SUPPLY_TEMPERATURE':
    case 'MBUS_METER_RETURN_TEMPERATURE':
      regUnit = ' °C';
      break;
    case 'MBUS_METER_COOLING_ENERGY':
      regUnit = ' GJ';
      break;
    case 'MBUS_METER_HEATING_ENERGY':
      regUnit = ' GJ / kWh';
      break;
    case 'MBUS_METER_ELECTRICITY_ENERGY':
      regUnit = ' kWh';
      break;
    case 'MBUS_METER_CURRENT':
      regUnit = ' mA';
      break;
    default:
      break;
  }
  return regUnit;
};
export default getMbusRegisterUnit;
