import type { ServiceClient, ServiceClientOverviewProps } from 'src/types/service_client';
import type { Client } from 'src/types/client';
import axios from 'src/utils/axios';
import parse from 'src/utils/parse';
import { isUndefined } from 'lodash';
import logger from 'src/utils/logger';
import i18next from 'i18next';

class ServiceClientApi {
  async create(serviceClient: ServiceClient): Promise<ServiceClient> {
    return new Promise((resolve, reject) => {
      const prop = 'submit';
      delete serviceClient[prop];
      // Create ServiceClient
      axios.post(`${process.env.REACT_APP_SERVICE_CLIENTS_URL}`, serviceClient).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        }
      }).catch((err) => {
        logger(err, 'error');
        reject(err);
      });
    });
  }

  async edit(serviceClient: ServiceClient): Promise<ServiceClient> {
    return new Promise((resolve, reject) => {
      let prop = 'submit';
      delete serviceClient[prop];
      if (serviceClient?.focal_user_id === 0) {
        prop = 'focal_user_id';
        delete serviceClient[prop];
      }
      // Update ServiceClient
      axios.put(`${process.env.REACT_APP_SERVICE_CLIENTS_URL}/${serviceClient?.client_id}`, serviceClient).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        }
      }).catch((err) => {
        logger(err, 'error');
        reject(err);
      });
    });
  }

  async getAll(page: number, limit: number, currentTab: number, search: string, sortColumn: any, sortDirection: boolean): Promise<ServiceClientOverviewProps> {
    return new Promise((resolve, reject) => {
      // Get By Id ServiceClient

      let sortList = {};
      if (sortColumn.name) {
        sortList = {
          'client.name': sortDirection ? 'ASC' : 'DESC',
          'client.email': sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.address) {
        sortList = {
          'client.city': sortDirection ? 'ASC' : 'DESC',
          'client.country': sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.is_activated) {
        sortList = {
          'client.is_activated': sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.total_devices) {
        sortList = {
          'client.total_device': sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.created_at) {
        sortList = {
          'client.created_at': sortDirection ? 'ASC' : 'DESC',
        };
      }

      const filter: any = {
        page,
        limit,
        sortList
      };

      let data: any;

      if (Object.keys(sortList)?.length === 0) {
        const sortListProp = 'sortList';
        delete filter[sortListProp];
      }

      if (search?.length > 0) {
        data = {
          Filter: filter,
          search
        };
      } else {
        data = {
          Filter: filter,
        };
      }

      if (!isUndefined(currentTab)) {
        if (currentTab !== -1) {
          filter.active = !!currentTab;
        }
      }

      axios.post(`${process.env.REACT_APP_SERVICE_CLIENTS_URL}/getAllServiceClient`, data).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getById(serviceClientId: number): Promise<ServiceClient> {
    return new Promise((resolve, reject) => {
      // Get By Id ServiceClient
      axios.get(`${process.env.REACT_APP_SERVICE_CLIENTS_URL}/${serviceClientId}`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(error);
      });
    });
  }

  async getAllServiceClientAutoComplete(search: string): Promise<Client[]> {
    return new Promise((resolve, reject) => {
      // Get By Id User
      axios.post(`${process.env.REACT_APP_SERVICE_CLIENTS_URL}/getAllServiceClientAutocomplete`, {
        search
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res:any = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getAllClientAutoComplete(search: string): Promise<Client[]> {
    return new Promise((resolve, reject) => {
      // Get By Id User
      axios.post(`${process.env.REACT_APP_SERVICE_CLIENTS_URL}/getAllClientAutoComplete`, {
        search
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res:any = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async deActivate(serviceClientId: string, isActive: number): Promise<ServiceClient> {
    // De-active ServiceClient
    return new Promise((resolve, reject) => {
      axios.patch(`${process.env.REACT_APP_SERVICE_CLIENTS_URL}/delete/${serviceClientId}`, {
        is_activated: !isActive
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async bulkDeActivate(serviceClientIds: string[]): Promise<ServiceClient> {
    return new Promise((resolve, reject) => {
      // Bulk De-active ServiceClients
      axios.post(`${process.env.REACT_APP_SERVICE_CLIENTS_URL}/bulk_deactive`, {
        list: serviceClientIds
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }
}

export const serviceClientApi = new ServiceClientApi();
