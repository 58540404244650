import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

const Client = createSvgIcon(
  <svg
    viewBox="0 0 15 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.33333 4.16667C3.33333 1.83333 5.16667 0 7.5 0C9.83333 0 11.6667 1.83333 11.6667 4.16667C11.6667 6.5 9.83333 8.33333 7.5 8.33333C5.16667 8.33333 3.33333 6.5 3.33333 4.16667ZM15 14.1667V15.8333C15 16.3333 14.6667 16.6667 14.1667 16.6667C13.6667 16.6667 13.3333 16.3333 13.3333 15.8333V14.1667C13.3333 12.75 12.25 11.6667 10.8333 11.6667H4.16667C2.75 11.6667 1.66667 12.75 1.66667 14.1667V15.8333C1.66667 16.3333 1.33333 16.6667 0.833333 16.6667C0.333333 16.6667 0 16.3333 0 15.8333V14.1667C0 11.8333 1.83333 10 4.16667 10H10.8333C13.1667 10 15 11.8333 15 14.1667ZM7.5 6.66667C6.08333 6.66667 5 5.58333 5 4.16667C5 2.75 6.08333 1.66667 7.5 1.66667C8.91667 1.66667 10 2.75 10 4.16667C10 5.58333 8.91667 6.66667 7.5 6.66667Z"
      fill="#546E7A"
    />
  </svg>,
  'Client'
);

export default Client;
