import axios from 'axios';
import i18next from 'i18next';
import jwtDecode from 'jwt-decode';
import { isUndefined } from 'lodash';
import logger from 'src/utils/logger';
import parse from 'src/utils/parse';
import { KeycloakLogin } from '../types/keycloak_login';
import { User } from '../types/user';

class AuthApi {
  async login({ email, password }): Promise<KeycloakLogin> {
    return new Promise((resolve, reject) => {
      try {
        // Find the user
        axios.post(`${process.env.REACT_APP_USERS_URL}/login`, {
          username: email,
          password,
        })
          .then((response) => {
            response = parse(response);
            if (!isUndefined(response?.data)) {
              const data = response?.data;
              resolve(data);
            } else {
              reject(new Error(i18next.t('login_credentials_error')));
              reject(new Error(i18next.t('Please check your email and password')));
            }
          })
          .catch((error) => {
            logger('error', error);
            reject(new Error(i18next.t('Please check your email and password')));
          });
      } catch (err) {
        logger('[Auth Api]:', err);
        reject(new Error(i18next.t('Please check your email and password')));
      }
    });
  }

  async register(): Promise<void> {
    console.log('register');
  }

  me(accessToken, permissions, is2FAEnabled: boolean = false): Promise<User> {
    return new Promise((resolve, reject) => {
      try {
        // Decode access token
        const decoded: any = jwtDecode(accessToken);
        if (!decoded) {
          reject(new Error('Invalid authorization token'));
          return;
        }

        const obj = {
          user_id: decoded?.sub,
          name: decoded.name,
          email: decoded.email,
          permissions,
          is_2fa_enabled: is2FAEnabled
        };

        resolve(obj);
      } catch (err) {
        reject(new Error('Invalid authorization token'));
      }
    });
  }

  async refreshSession(refreshToken): Promise<KeycloakLogin> {
    return new Promise((resolve, reject) => {
      try {
        // Find the user
        axios.post(`${process.env.REACT_APP_USERS_URL}/refresh_token`, {
          refresh_token: refreshToken
        })
          .then((response) => {
            response = parse(response);
            if (!isUndefined(response?.data)) {
              const data = !isUndefined(response?.data?.data) ? response?.data?.data : response?.data;
              localStorage.setItem('accessToken', data?.access_token);
              localStorage.setItem('refreshToken', data?.refresh_token);
              resolve(data);
            } else {
              reject(new Error('Please check your email and password'));
            }
          })
          .catch((error) => {
            logger(error, 'error');
            reject(new Error('Token Expired'));
          });
      } catch (err) {
        reject(new Error('Internal server error'));
      }
    });
  }
}

export const authApi = new AuthApi();
