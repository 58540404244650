import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

const Globe = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
      stroke="white"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.60015 12C7.60015 18.0751 9.57009 23 12.0001 23C14.4302 23 16.4001 18.0751 16.4001 12C16.4001 5.92487 14.4302 1 12.0001 1C9.57009 1 7.60015 5.92487 7.60015 12Z"
      stroke="white"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 12.0002H23"
      stroke="white"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'Globe'
);

export default Globe;
