import i18next from 'i18next';
import { isUndefined, trim } from 'lodash';
import type { Notification, NotificationOverviewProps } from 'src/types/notification';
import axios from 'src/utils/axios';
import getId from 'src/utils/getId';
import logger from 'src/utils/logger';
import parse from 'src/utils/parse';

class NotificationApi {
  async create(notification: Notification): Promise<Notification> {
    return new Promise((resolve, reject) => {
      const prop = 'submit';
      delete notification[prop];
      // Create Notification
      axios.post(`${process.env.REACT_APP_NOTIFICATIONS_URL}`, notification).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        }
      }).catch((err) => {
        logger(err, 'error');
        reject(err);
      });
    });
  }

  async edit(notification: Notification): Promise<Notification> {
    return new Promise((resolve, reject) => {
      const prop = 'submit';
      delete notification[prop];
      // Update Notification
      axios.put(`${process.env.REACT_APP_NOTIFICATIONS_URL}/${getId()}`, notification).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        }
      }).catch((err) => {
        logger(err, 'error');
        reject(err);
      });
    });
  }

  async getAllUserNotification(page: number, limit: number, search: string, sortColumn: any, sortDirection: boolean): Promise<NotificationOverviewProps> {
    return new Promise((resolve, reject) => {
      // Get All User Notifications

      let sortList = {};
      if (sortColumn.notification_id) {
        sortList = {
          'notification.notification_id': sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.title) {
        sortList = {
          'notification.title_en': sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.description) {
        sortList = {
          'notification.description_en': sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.notification_type) {
        sortList = {
          'notification.notification_type': sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.notification_category) {
        sortList = {
          'notification.notification_category': sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.date) {
        sortList = {
          'notification.notification_start_at': sortDirection ? 'ASC' : 'DESC',
          'notification.notification_end_at': sortDirection ? 'ASC' : 'DESC',
        };
      }

      const filter: any = {
        page,
        limit,
        sortList
      };

      if (Object.keys(sortList)?.length === 0) {
        const prop = 'sortList';
        delete filter[prop];
      }

      const payload = {
        Filter: filter,
        search: ''
      };

      if (trim(search)?.length > 0) {
        payload.search = search;
      } else {
        const prop = 'search';
        delete payload[prop];
      }

      axios.post(`${process.env.REACT_APP_NOTIFICATIONS_URL}/getAllUserNotification`, payload).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getAll(page: number, limit: number, search: string, sortColumn: any, sortDirection: boolean): Promise<NotificationOverviewProps> {
    return new Promise((resolve, reject) => {
      // Get All Notifications

      let sortList = {};
      if (sortColumn.notification_id) {
        sortList = {
          'notification.notification_id': sortDirection ? 'ASC' : 'DESC',
        };
      }
      if (sortColumn.title) {
        sortList = {
          'notification.title_en': sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.description) {
        sortList = {
          'notification.description_en': sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.notification_type) {
        sortList = {
          'notification.notification_type': sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.notification_category) {
        sortList = {
          'notification.notification_category': sortDirection ? 'ASC' : 'DESC',
        };
      }

      if (sortColumn.date) {
        sortList = {
          'notification.notification_start_at': sortDirection ? 'ASC' : 'DESC',
          'notification.notification_end_at': sortDirection ? 'ASC' : 'DESC',
        };
      }

      const filter: any = {
        page,
        limit,
        sortList
      };

      if (Object.keys(sortList)?.length === 0) {
        const prop = 'sortList';
        delete filter[prop];
      }

      const payload = {
        Filter: filter,
        search: ''
      };

      if (trim(search)?.length > 0) {
        payload.search = search;
      } else {
        const prop = 'search';
        delete filter[prop];
      }

      axios.post(`${process.env.REACT_APP_NOTIFICATIONS_URL}/getAllNotification`, payload).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getById(notificationId: number): Promise<Notification> {
    return new Promise((resolve, reject) => {
      // Get By Id Notification
      axios.get(`${process.env.REACT_APP_NOTIFICATIONS_URL}/${notificationId}`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async getAllNotificationsDropdown(): Promise<Notification[]> {
    return new Promise((resolve, reject) => {
      // Get All Notification Dropdown
      axios.post(`${process.env.REACT_APP_NOTIFICATIONS_URL}/getAll`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async bulkRead(): Promise<Notification[]> {
    return new Promise((resolve, reject) => {
      // Get All Notification Dropdown
      axios.post(`${process.env.REACT_APP_NOTIFICATIONS_URL}/bulkRead`).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async singleRead(notificationId: any): Promise<Notification[]> {
    return new Promise((resolve, reject) => {
      // Get All Notification Dropdown
      axios.post(`${process.env.REACT_APP_NOTIFICATIONS_URL}/read`, {
        notifictaionsArray: [notificationId]
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const res = response?.data;
          resolve(res);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async deActivate(notificationId: string, isActive: boolean): Promise<Notification> {
    return new Promise((resolve, reject) => {
      // De-active Notification
      axios.patch(`${process.env.REACT_APP_NOTIFICATIONS_URL}/DeActive/${notificationId}`, {
        is_activated: !isActive
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }

  async bulkDeActivate(notificationIds: string[]): Promise<Notification> {
    return new Promise((resolve, reject) => {
      // Bulk De-active Notifications
      axios.post(`${process.env.REACT_APP_NOTIFICATIONS_URL}/bulkDeActive`, {
        list: notificationIds
      }).then((response) => {
        response = parse(response);
        if (!isUndefined(response?.data)) {
          const data = response?.data;
          resolve(data);
        } else {
          reject(new Error(i18next.t('invalid_server_response')));
        }
      }).catch((error) => {
        logger(error, 'error');
        reject(new Error(i18next.t('internal_server_error')));
      });
    });
  }
}

export const notificationApi = new NotificationApi();
