import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

const Report = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.275 6.975L14.025 1.725C13.875 1.575 13.725 1.5 13.5 1.5H6C5.175 1.5 4.5 2.175 4.5 3V21C4.5 21.825 5.175 22.5 6 22.5H18C18.825 22.5 19.5 21.825 19.5 21V7.5C19.5 7.275 19.425 7.125 19.275 6.975ZM13.5 3.3L17.7 7.5H13.5V3.3ZM18 21H6V3H12V7.5C12 8.325 12.675 9 13.5 9H18V21Z"
      fill="#5E7782"
    />
    <path
      d="M7.5 16.5H16.5V18H7.5V16.5Z"
      fill="#5E7782"
    />
    <path
      d="M7.5 12H16.5V13.5H7.5V12Z"
      fill="#5E7782"
    />
  </svg>,
  'Report'
);

export default Report;
